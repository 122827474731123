/**
 *
 * ProductsPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import format from 'date-fns/format';

import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';

import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { connect } from 'react-redux';

import * as actionTypes from '../../store/actions';
import { isFavorite } from '../../store/selectors';

import Concat from '../../components/Concat';
import Price from '../../components/Price';
import Can from '../../components/Can';

import { FILES_URL } from '../../config/constants';
import Dosage from '../../components/Dosage';
import { get } from 'lodash';
import CopyFormulaDialog from '../../components/ProductSections/CopyFormulaDialog';

// Utils
const styles = () => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    border: `1px solid #A5D8DA`,
    boxShadow: 'none',
    backgroundSize: '200px auto',
    backgroundPosition: 'top right',
  },
  imageWrapper: {
    height: '150px',
    padding: '0 20px 20px',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '0 auto 0',
  },
  actions: {
    alignItems: 'flex-end',
    margin: 'auto 0 0 0',
  },
});

const StyledConcat = styled(Concat)`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledPrice = withStyles(() => ({
  textAlign: 'right',
}))(Price);

const Item = props => {
  const { classes, item, onFavoriteToggle, isFavoriteItem } = props;
  let totals = get(item, ['totals', 0, 'percentage_frames']);
  if (!totals || totals === null) {
    totals = undefined;
  }
  const completeness = parseFloat(item.completeness).toFixed(0);

  return (
    <Card
      className={classes.card}
      elevation={2}
      id={item.id}
      data-productid={item.id}
    >
      <CardContent>
        <div className={classes.imageWrapper}>
          {item.file && item.file.path && (
            <img
              className={classes.image}
              src={`${FILES_URL}${item.file.path}`}
              alt={item.brand_name}
            />
          )}
        </div>
        <Can
          perform="product.field.id"
          yes={() => (
            <>
              {item.id}
              <br />
            </>
          )}
          no={() => (
            <>
              {item.id}
              <br />
            </>
          )}
        />
        <StyledConcat
          first={item.brand_name}
          second={item.brand_owner}
          divider=" - "
          empty="-"
        />
        <StyledConcat
          first={item.variant_name}
          second=""
          divider=" - "
          empty="-"
        />
        <StyledConcat first={item.country} second="" divider=" - " empty="-" />
        <StyledConcat
          first={item.category}
          second={item.subcategory}
          divider=" / "
          empty="-"
        />
        <StyledConcat
          first={item.format}
          second={item.subformat}
          divider=" / "
          empty="-"
        />
        <br />
        <StyledConcat
          label="Ingredients list"
          first={item.date_ingredient_list || item.date_entered}
          divider=" - "
          empty="-"
        />
        <StyledConcat
          label="Entered at"
          first={item.date_entered}
          divider=" - "
          empty="-"
        />
        <StyledConcat
          label="Formulation status"
          first={item.status}
          divider=" - "
          empty="-"
        />
        <br />
        <Can
          perform="product.field.price_active"
          yes={() => (
            <>
              {item.price_active ? (
                <>
                  <StyledPrice
                    value={item.price_active}
                    empty="-"
                    decimals={0}
                  />
                  /ton
                </>
              ) : (
                '-'
              )}
              <br />
            </>
          )}
        />
        {item && item.ingredient_count ? item.ingredient_count.ranking : 0}{' '}
        ingredients
        <Can
          perform="product.field.completeness"
          yes={() => (
            <StyledConcat
              label="Completeness: "
              first={completeness ? completeness.toString(10) : '-'}
              postfix="%"
              empty="-"
            />
          )}
        />
      </CardContent>
      <CardActions className={classes.actions}>
        <Can
          perform="product:detail"
          yes={() => (
            <Link to={`/product/${item.id}`}>
              <IconButton aria-label="Details" color="primary">
                <InfoIcon />
              </IconButton>
            </Link>
          )}
          no={() => (
            <Link to={`/product/detail-demo`}>
              <IconButton aria-label="Details" color="primary">
                <InfoIcon />
              </IconButton>
            </Link>
          )}
        />
        <IconButton
          aria-label="Favourite"
          color={isFavoriteItem ? 'primary' : 'secondary'}
          onClick={onFavoriteToggle}
        >
          <FavoriteIcon />
        </IconButton>
        <Can
          perform="formula:edit"
          yes={() => <CopyFormulaDialog id={item.id} />}
          no={() => null}
        />
      </CardActions>
    </Card>
  );
};

const mapStateToProps = (state, props) => {
  return {
    isFavoriteItem: isFavorite(state, 'products', props.item),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onFavoritesAdded: () =>
      dispatch({
        type: actionTypes.ADD_FAVORITE,
        store: 'products',
        item: props.item,
      }),
    onFavoriteRemoved: () =>
      dispatch({
        type: actionTypes.REMOVE_FAVORITE,
        store: 'products',
        item: props.item,
      }),
    onFavoriteToggle: () =>
      dispatch({
        type: actionTypes.TOGGLE_FAVORITE,
        store: 'products',
        item: props.item,
      }),
  };
};

Item.defaultProps = {
  item: {},
};

Item.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.object.isRequired,
  onFavoriteToggle: PropTypes.func.isRequired,
  isFavoriteItem: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Item));
